<script setup lang="ts"></script>

<template>
    <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.09091 1.09091C7.09091 0.488417 6.60249 0 6 0C5.39751 0 4.90909 0.488416 4.90909 1.09091V4.90909H1.09091C0.488417 4.90909 0 5.39751 0 6C0 6.60249 0.488416 7.09091 1.09091 7.09091H4.90909V10.9091C4.90909 11.5116 5.39751 12 6 12C6.60249 12 7.09091 11.5116 7.09091 10.9091V7.09091H10.9091C11.5116 7.09091 12 6.60249 12 6C12 5.39751 11.5116 4.90909 10.9091 4.90909H7.09091V1.09091Z"
            fill="currentColor"
        />
    </svg>
</template>
